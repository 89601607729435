<template>
  <v-container>
    <monaco-editor v-model="nodejs.M.content"></monaco-editor>
  </v-container>
</template>

<script>
import endpointTemplateForm from '@/template/endpointTemplate.json'

export default {
  name: 'ProcessorNodejsForm',
  model: {
    prop: 'nodejs'
  },
  components: {
    MonacoEditor: () => import('@/components/MonacoEditor')
  },
  props: {
    nodejs: {
      type: Object,
      default () {
        return {
        }
      },
      required: true
    }
  },
  data: () => ({
    objectKey: null,
    objectValue: null,
    color: 'red'
  }),
  computed: {
    endpointEnum: function () {
      return endpointTemplateForm.enum.nodejs
    },
    fieldRequire: function () {
      return v => !!v || 'field is required'
    },
    counterRequire: function (lessLength, moreLength) {
      if (lessLength && moreLength) {
        return v => ((v || '').length >= lessLength && (v || '').length <= moreLength) || `field must be ${lessLength}-${moreLength} characters`
      } else {
        return v => (v || '').length <= lessLength || `field must be ${lessLength} characters`
      }
    }
  },
  methods: {
  }
}
</script>
<style>
.mnc {
  color: v-bind(color);
}
</style>
